import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {Icon, Stack} from '@halp/ui';
import {STUDENTS_BY_FUNNEL_STAGE} from '../data';
import {Metric} from '../Metric';
import style from './Funnel.module.css';

export function Funnel() {
	const handOffTotal = STUDENTS_BY_FUNNEL_STAGE.HAND_OFF.length;
	const verificationTotal =
		STUDENTS_BY_FUNNEL_STAGE.VERIFICATION.length + handOffTotal;
	const onboardingTotal =
		STUDENTS_BY_FUNNEL_STAGE.ONBOARDING.length + verificationTotal;
	const signupTotal = STUDENTS_BY_FUNNEL_STAGE.SIGN_UP.length + onboardingTotal;

	return (
		<div className={style.Funnel}>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Signup" color="blurple" value={signupTotal} />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Onboarding" color="blue" value={onboardingTotal} />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Verification" value={verificationTotal} color="pink" />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Metric title="Hand-off" value={handOffTotal} color="green" />
		</div>
	);
}
