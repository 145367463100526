import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import {Icon, Stack} from '@halp/ui';
import {AMBASSADORS_BY_HIRING_STAGE} from '../data';
import {Metric} from '../Metric';
import style from './AmbassadorsFunnel.module.css';

export function AmbassadorsFunnel() {
	const activeTotal = AMBASSADORS_BY_HIRING_STAGE.ACTIVE.length;
	const trainingTotal =
		AMBASSADORS_BY_HIRING_STAGE.TRAINING.length + activeTotal;
	const interviewTotal =
		AMBASSADORS_BY_HIRING_STAGE.INTERVIEW.length + trainingTotal;
	const signupTotal =
		AMBASSADORS_BY_HIRING_STAGE.SIGN_UP.length + interviewTotal;

	return (
		<div className={style.AmbassadorsFunnel}>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Signup" color="yellow" value={signupTotal} />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Interview" color="pink" value={interviewTotal} />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Stack
				justifyContent="flex-start"
				direction="row"
				alignItems="center"
				spacing="xs"
			>
				<Metric title="Training" value={trainingTotal} color="blurple" />
				<Icon icon={faCaretRight} size="xl" />
			</Stack>
			<Metric title="Active" value={activeTotal} color="green" />
		</div>
	);
}
