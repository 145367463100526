import {
	faBoltLightning,
	faGraduationCap,
	faClock,
} from '@fortawesome/free-solid-svg-icons';
import {Icon} from '@halp/ui';
import {dayjs} from '@halp/util';
import {CAMPAIGNS, CAMPAIGNS_BY_STATUS} from '../data';
import {Metric} from '../Metric';
import style from './CampaignsSummary.module.css';

export function CampaignsSummary() {
	const bestCampaign = CAMPAIGNS_BY_STATUS.ACTIVE.reduce((acc, campaign) => {
		if (campaign.metrics.handOff > acc.metrics.handOff) {
			return campaign;
		}
		return acc;
	});

	const newestCampaign = CAMPAIGNS_BY_STATUS.ACTIVE.reduce((acc, campaign) => {
		if (campaign.createdAt > acc.createdAt) {
			return campaign;
		}
		return acc;
	});

	return (
		<div className={style.CampaignsSummary}>
			<Metric
				title="Total Campaigns"
				value={CAMPAIGNS.length}
				tag={
					<>
						<Icon icon={faBoltLightning} /> {CAMPAIGNS_BY_STATUS.ACTIVE.length}{' '}
						Live
					</>
				}
				tagColor="green"
			/>
			<Metric
				title="Top Performing Campaign"
				value={bestCampaign.name}
				tag={
					<>
						<Icon icon={faGraduationCap} /> {bestCampaign.metrics.handOff}
					</>
				}
				tagColor="green"
			/>
			<Metric
				title="Newest Campaign"
				value={newestCampaign.name}
				tag={
					<>
						<Icon icon={faClock} />{' '}
						{dayjs(newestCampaign.createdAt).fromNow(true)}
					</>
				}
				tagColor="purple"
			/>
		</div>
	);
}
