import {
	faChalkboardTeacher,
	faUserGraduate,
	faBullhorn,
	faHome,
	faFunnelDollar,
	faUser,
} from '@fortawesome/free-solid-svg-icons';
import {SidebarNav} from '@halp/ui';
import {Paths} from '@halp/foundation/Paths';
import {AdminUserMenu, useUser} from '@halp/foundation/Users';
import {imgLogoWhite} from '@halp/images';
import type {Item} from '@halp/ui/SidebarNav/SidebarNav';

export function SideNav({
	setOpenMenu,
}: {
	mobile?: boolean;
	setOpenMenu?: (open: boolean) => void;
}) {
	const {user} = useUser();
	const items: Item[] = [
		{type: 'heading', text: 'Dashboard'} as const,
		{text: 'Home', path: Paths.ambassador.index.url(), icon: faHome},
		...(user?.partnerProfile
			? [
					{
						text: 'Ambassadors',
						path: Paths.partner.ambassadors.url(),
						icon: faChalkboardTeacher,
					},
				]
			: []),
		{
			text: 'Campaigns',
			path: Paths.ambassador.campaigns.url(),
			icon: faBullhorn,
		},
		{type: 'heading', text: 'Leads'} as const,
		{
			text: 'Funnel',
			path: Paths.ambassador.index.url(),
			icon: faFunnelDollar,
			disabled: true,
			disabledMessage: 'Upgrade to full version',
		},
		{
			text: 'Students',
			path: Paths.ambassador.index.url(),
			icon: faUserGraduate,
			disabled: true,
			disabledMessage: 'Upgrade to full version',
		},
		{type: 'heading', text: 'Organization'} as const,
		{
			text: 'Users',
			path: Paths.ambassador.index.url(),
			icon: faUser,
			disabled: true,
			disabledMessage: 'Upgrade to full version',
		},
		{type: 'break'} as const,
	];

	return (
		<SidebarNav logo={imgLogoWhite} items={items} toggle={setOpenMenu}>
			<AdminUserMenu />
		</SidebarNav>
	);
}
