import {faker} from '@faker-js/faker';
faker.seed(0);

export type FunnelStage =
	| 'SIGN_UP'
	| 'ONBOARDING'
	| 'VERIFICATION'
	| 'HAND_OFF';

export const HIRING_STAGES = [
	'SIGN_UP',
	'INTERVIEW',
	'TRAINING',
	'ACTIVE',
] as const;

export type HiringStage = (typeof HIRING_STAGES)[number];

interface Metrics {
	signups: number;
	onboarding: number;
	verification: number;
	handOff: number;
}

interface Ambassador {
	first: string;
	last: string;
	hiringStage: HiringStage;
	createdAt: Date;
	students: Student[];
	metrics: Metrics;
}

interface Student {
	first: string;
	last: string;
	funnelStage: FunnelStage;
	createdAt: Date;
	campaign: Campaign;
	ambassador: Ambassador;
}

interface Campaign {
	name: string;
	active: boolean;
	url: string;
	createdAt: Date;
	students: Student[];
	metrics: Metrics;
}

export const CAMPAIGNS = Array.from(
	{length: faker.number.int({min: 7, max: 13})},
	(_, i) => {
		return {
			name: `${faker.helpers.arrayElement([
				'Campaign',
				'Campaign',
				'Campaign',
				'Youtube',
				'Omni-Channel',
				'Test',
			])} ${i + 1}`,
			active: true,
			url: `http://halp.co`,
			createdAt: faker.date.past(),
			students: [] as Student[],
			metrics: {
				signups: 0,
				onboarding: 0,
				verification: 0,
				handOff: 0,
			},
		};
	},
);

export const AMBASSADORS = Array.from(
	{length: faker.number.int({min: 25, max: 45})},
	() => {
		const hiringStage = faker.helpers.arrayElement([
			...HIRING_STAGES,
			'ACTIVE',
			'ACTIVE',
			'ACTIVE',
			'ACTIVE',
		]);

		return {
			first: faker.person.firstName(),
			last: faker.person.lastName(),
			hiringStage,
			createdAt: faker.date.past(),
			students: [] as Student[],
			metrics: {
				signups: 0,
				onboarding: 0,
				verification: 0,
				handOff: 0,
			},
		};
	},
);

const firstCampaign = faker.number.int({
	min: 0,
	max: CAMPAIGNS.length - 1,
});

const secondCampaign = faker.number.int({
	min: 0,
	max: CAMPAIGNS.length - 1,
});

const thirdCampaign = faker.number.int({
	min: 0,
	max: CAMPAIGNS.length - 1,
});

export const STUDENTS = Array.from(
	{length: faker.number.int({min: 300, max: 525})},
	() => {
		const rand = faker.number.float();
		let funnelStage: FunnelStage = 'HAND_OFF';
		if (rand < 0.875) funnelStage = 'VERIFICATION';
		if (rand < 0.775) funnelStage = 'ONBOARDING';
		if (rand < 0.5) funnelStage = 'SIGN_UP';

		const campaign = faker.helpers.arrayElement([
			...CAMPAIGNS,
			CAMPAIGNS[firstCampaign],
			CAMPAIGNS[firstCampaign],
			CAMPAIGNS[firstCampaign],
			CAMPAIGNS[firstCampaign],
			CAMPAIGNS[secondCampaign],
			CAMPAIGNS[secondCampaign],
			CAMPAIGNS[secondCampaign],
			CAMPAIGNS[thirdCampaign],
			CAMPAIGNS[thirdCampaign],
		]);

		const ambassador = faker.helpers.arrayElement(
			AMBASSADORS.filter((a) => a.hiringStage === 'ACTIVE'),
		);

		const student = {
			first: faker.person.firstName(),
			last: faker.person.lastName(),
			funnelStage: funnelStage,
			createdAt: faker.date.past(),
			campaign: campaign,
			ambassador: ambassador,
		};

		campaign.students.push(student);
		ambassador.students.push(student);
		return student;
	},
);

// put metrics counts on campaigns
CAMPAIGNS.forEach((campaign) => {
	const handOff = campaign.students.filter(
		(student) => student.funnelStage === 'HAND_OFF',
	).length;
	const verification =
		campaign.students.filter(
			(student) => student.funnelStage === 'VERIFICATION',
		).length + handOff;
	const onboarding =
		campaign.students.filter((student) => student.funnelStage === 'ONBOARDING')
			.length + verification;
	const signups =
		campaign.students.filter((student) => student.funnelStage === 'SIGN_UP')
			.length + onboarding;

	if (handOff < 10) {
		campaign.active = faker.number.float() > 0.33;
	}

	campaign.metrics = {
		signups,
		onboarding,
		verification,
		handOff,
	};
});

AMBASSADORS.forEach((ambassador) => {
	const handOff = ambassador.students.filter(
		(student) => student.funnelStage === 'HAND_OFF',
	).length;
	const verification =
		ambassador.students.filter(
			(student) => student.funnelStage === 'VERIFICATION',
		).length + handOff;
	const onboarding =
		ambassador.students.filter(
			(student) => student.funnelStage === 'ONBOARDING',
		).length + verification;
	const signups =
		ambassador.students.filter((student) => student.funnelStage === 'SIGN_UP')
			.length + onboarding;

	ambassador.metrics = {
		signups,
		onboarding,
		verification,
		handOff,
	};
});

export const CAMPAIGNS_BY_STATUS = CAMPAIGNS.reduce(
	(acc, campaign) => {
		acc[campaign.active ? 'ACTIVE' : 'INACTIVE'].push(campaign);
		return acc;
	},
	{
		ACTIVE: [] as Campaign[],
		INACTIVE: [] as Campaign[],
	},
);

export const STUDENTS_BY_FUNNEL_STAGE = STUDENTS.reduce(
	(acc, student) => {
		acc[student.funnelStage].push(student);
		return acc;
	},
	{
		SIGN_UP: [] as Student[],
		ONBOARDING: [] as Student[],
		VERIFICATION: [] as Student[],
		HAND_OFF: [] as Student[],
	},
);

export const AMBASSADORS_BY_HIRING_STAGE = AMBASSADORS.reduce(
	(acc, ambassador) => {
		acc[ambassador.hiringStage].push(ambassador);
		return acc;
	},
	{
		SIGN_UP: [] as Ambassador[],
		INTERVIEW: [] as Ambassador[],
		TRAINING: [] as Ambassador[],
		ACTIVE: [] as Ambassador[],
	},
);
