import {Surface, Heading, Tag, Stack} from '@halp/ui';
import style from './Metric.module.css';
import type {ComponentProps, ReactNode} from 'react';

interface PropsWithTag extends BaseProps {
	tag: ReactNode;
	tagColor: ComponentProps<typeof Tag>['color'];
}

interface BaseProps {
	title: ReactNode;
	value: ReactNode;
	color?: ComponentProps<typeof Surface>['color'];
}

interface Props extends BaseProps {
	tag?: never;
	tagColor?: never;
}

export function Metric({title, value, color, ...props}: Props | PropsWithTag) {
	return (
		<Surface color={color} className={style.Metric}>
			<Stack direction="column" alignItems="flex-start">
				<Heading textWrap="nowrap" type="h5" color="grey" spacing="lg">
					{title}
				</Heading>
				<Stack fullWidth justifyContent="flex-start" spacing="xs">
					<Heading type="h3">{value}</Heading>
					{props.tag ? (
						<Tag color={props.tagColor} className={style.Tag}>
							{props.tag}
						</Tag>
					) : null}
				</Stack>
			</Stack>
		</Surface>
	);
}
