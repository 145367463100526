import {AmbassadorInfo} from '@halp/foundation/Ambassadors';
import {Heading, Page, Stack} from '@halp/ui';
import {useUser, withUserProtectedRoute} from '@halp/foundation/Users';
import {CampaignsTable, Funnel, Summary} from '../modules/Trios';
import {constructServerSideProps} from '../utils/ssr';
import {AmbassadorMetrics} from '../modules/AmbassadorMetrics';

function TriosPartnerIndex() {
	const {user} = useUser();

	if (!user?.partnerProfile) {
		return null;
	}

	return (
		<Page>
			<Stack fullWidth direction="column" spacing="xl" alignItems="flex-start">
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<AmbassadorInfo businessName="triOS" />
					<Summary />
				</Stack>
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<Heading type="h3">Students</Heading>
					<Funnel />
				</Stack>
				<Stack
					fullWidth
					direction="column"
					spacing="md"
					alignItems="flex-start"
				>
					<Heading type="h3">Campaigns</Heading>
					<CampaignsTable />
				</Stack>
			</Stack>
		</Page>
	);
}

function AmbassadorIndex() {
	const {user} = useUser();

	if (user?.partnerProfile) {
		return <TriosPartnerIndex />;
	} else if (user?.ambassadorProfile) {
		return (
			<Page>
				<Stack
					direction="column"
					alignItems="flex-start"
					justifyContent="flex-start"
					spacing="lg"
				>
					<AmbassadorInfo
						businessName={user.ambassadorProfile.businessName}
						publicId={user.ambassadorProfile.publicId}
						name={user?.name}
						email={user?.email}
					/>
					<AmbassadorMetrics />
				</Stack>
			</Page>
		);
	}

	return null;
}

export default withUserProtectedRoute(AmbassadorIndex, {
	role: ['partner', 'ambassador'],
});
export const getServerSideProps = constructServerSideProps({
	dictionaries: ['partner', 'ambassador'],
});
