import {
	faCaretUp,
	faCaretDown,
	faBoltLightning,
} from '@fortawesome/free-solid-svg-icons';
import {Icon} from '@halp/ui';
import {
	STUDENTS,
	CAMPAIGNS,
	CAMPAIGNS_BY_STATUS,
	AMBASSADORS_BY_HIRING_STAGE,
} from '../data';
import {Metric} from '../Metric';
import style from './Summary.module.css';

export function Summary() {
	return (
		<div className={style.Summary}>
			<Metric
				title="Total Students"
				value={STUDENTS.length}
				tag={
					<>
						<Icon icon={faCaretUp} /> 7.5%
					</>
				}
				tagColor="green"
			/>
			<Metric
				title="Active Ambassadors"
				value={AMBASSADORS_BY_HIRING_STAGE.ACTIVE.length}
				tag={
					<>
						<Icon icon={faCaretDown} /> 1
					</>
				}
				tagColor="red"
			/>
			<Metric
				title="Total Campaigns"
				value={CAMPAIGNS.length}
				tag={
					<>
						<Icon icon={faBoltLightning} /> {CAMPAIGNS_BY_STATUS.ACTIVE.length}{' '}
						Live
					</>
				}
				tagColor="green"
			/>
		</div>
	);
}
