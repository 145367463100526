import {useCallback, useState} from 'react';
import {QueryTable} from '@halp/foundation/DataTable';
import type {Ambassador} from '@halp/util';
import {formatDateTime} from '@halp/util';
import style from './AmbassadorsTable.module.css';
import {PartnerAmbassadorIndexDocument} from './PartnerAmbassadorIndex.query';

interface Row {
	id: string;
	businessName: string;
	type: Ambassador;
	insertedAt: Date;
	user?: {
		id: string;
		email: string;
		name: string;
	};
}

function DateCell({insertedAt}: Row) {
	return <>{formatDateTime(insertedAt, 'DateMonthShort')}</>;
}

export function AmbassadorsTable() {
	const [orderBy, setOrderBy] = useState<string | undefined>('insertedAt');
	const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc' | undefined>(
		'desc',
	);

	const onSortColumn = useCallback(
		(dataKey: string, dir?: 'asc' | 'desc') => {
			setOrderBy(dir ? dataKey : undefined);
			setSortingOrder(dir);
		},
		[setOrderBy, setSortingOrder],
	);

	return (
		<div className={style.AmbassadorsTable}>
			<QueryTable
				infiniteQuery={PartnerAmbassadorIndexDocument}
				sortColumn={orderBy}
				onSortColumn={onSortColumn}
				sortType={sortingOrder}
				dataKey="ambassadors"
				options={{
					limit: 30,
				}}
				columns={[
					{
						key: 'user.name',
						label: 'Name',
						sortable: true,
					},
					{
						key: 'insertedAt',
						label: 'Created At',
						sortable: true,
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						customCellRender: DateCell as any,
					},
				]}
			/>
		</div>
	);
}
