import {faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons';
import {Avatar, Heading, Paragraph, Stack, Icon} from '@halp/ui';

interface Props {
	businessName?: string | null;
	publicId?: string | null;
	name?: string | null;
	email?: string | null;
}

export function AmbassadorInfo({businessName, publicId, name, email}: Props) {
	return (
		<Stack direction="row" spacing="md" justifyContent="flex-start">
			<Avatar text={businessName} color="secondary" />
			<Stack
				direction="column"
				justifyContent="space-between"
				alignItems="baseline"
				spacing="none"
			>
				{businessName ? (
					<Heading type="h4" spacing="none">
						{businessName}
					</Heading>
				) : null}
				{name ? (
					<Stack justifyContent="flex-start" spacing="xs">
						<Icon icon={faUser} />
						<Paragraph>
							{name} {publicId ? `(${publicId})` : null}
						</Paragraph>
					</Stack>
				) : null}
				{email ? (
					<Stack justifyContent="flex-start" spacing="xs">
						<Icon icon={faEnvelope} />
						<Paragraph>{email}</Paragraph>
					</Stack>
				) : null}
			</Stack>
		</Stack>
	);
}
