import {faChain} from '@fortawesome/free-solid-svg-icons';
import {useCallback, useMemo, useState} from 'react';
import {DataTable} from '@halp/foundation/DataTable';
import {Icon, Link, Tag} from '@halp/ui';
import {accessObject, formatDateTime} from '@halp/util';
import {CAMPAIGNS} from '../data';
import style from './CampaignsTable.module.css';

function URLCell({url}: {url: string}) {
	return (
		<Link href={url}>
			<Icon icon={faChain} />
		</Link>
	);
}

function StatusCell({active}: {active: boolean}) {
	return (
		<Tag className={style.Tag} color={active ? 'green' : 'red'}>
			{active ? 'Active' : 'Archived'}
		</Tag>
	);
}

function DateCell({createdAt}: {createdAt: Date}) {
	return <>{formatDateTime(createdAt, 'DateMonthShort')}</>;
}

export function CampaignsTable() {
	const [orderBy, setOrderBy] = useState<string | undefined>('metrics.handOff');
	const [sortingOrder, setSortingOrder] = useState<'asc' | 'desc' | undefined>(
		'desc',
	);

	const sortedData = useMemo(() => {
		const reverse = sortingOrder === 'asc' ? 1 : -1;
		if (!sortingOrder || !orderBy) {
			return CAMPAIGNS;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return CAMPAIGNS.slice().sort((a: any, b: any) => {
			const valA = accessObject(a, orderBy);
			const valB = accessObject(b, orderBy);

			// if is date compare them
			if (valA instanceof Date && valB instanceof Date) {
				return ((valA as Date).getTime() - (valB as Date).getTime()) * reverse;
			}

			return (
				`${valA}`.localeCompare(
					`${valB}`,
					navigator.languages[0] || navigator.language,
					{
						numeric: true,
						ignorePunctuation: true,
					},
				) * reverse
			);
		});
	}, [sortingOrder, orderBy]);

	const onSortColumn = useCallback(
		(dataKey: string, dir?: 'asc' | 'desc') => {
			setOrderBy(dir ? dataKey : undefined);
			setSortingOrder(dir);
		},
		[setOrderBy, setSortingOrder],
	);

	return (
		<div className={style.CampaignsTable}>
			<DataTable
				data={sortedData}
				sortColumn={orderBy}
				onSortColumn={onSortColumn}
				sortType={sortingOrder}
				columns={[
					{
						key: 'name',
						label: 'Name',
						sortable: true,
					},
					{
						key: 'metrics.signups',
						label: '# Sign Up',
						sortable: true,
					},
					{
						key: 'metrics.onboarding',
						label: '# Onboarding',
						sortable: true,
					},
					{
						key: 'metrics.verification',
						label: '# Verification',
						sortable: true,
					},
					{
						key: 'metrics.handOff',
						label: '# Hand-off',
						sortable: true,
					},
					{
						key: 'active',
						label: 'Active',
						sortable: true,
						width: 130,
						customCellRender: StatusCell,
					},
					{
						key: 'url',
						label: 'URL',
						sortable: false,
						width: 70,
						customCellRender: URLCell,
					},
					{
						key: 'createdAt',
						label: 'Created',
						sortable: true,
						customCellRender: DateCell,
					},
				]}
			/>
		</div>
	);
}
