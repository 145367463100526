import {Image, Heading, Stack, Link} from '@halp/ui';
import {UserMenu, useUser} from '@halp/foundation/Users';
import {imgLogo} from '@halp/images';
import style from './TopNav.module.css';

export function TopNav() {
	const {user} = useUser();

	return (
		<Stack direction="row" justifyContent="space-between" alignItems="center">
			<Link href="/">
				<Stack direction="row" spacing="md" alignItems="center">
					<Image
						alt="Halp logo"
						loading="eager"
						className={style.Logo}
						src={imgLogo}
					/>
					<Heading className={style.Header}>
						{user?.ambassadorProfile
							? 'Halp Ambassador Dashboard'
							: 'Halp Partner Dashboard'}
					</Heading>
				</Stack>
			</Link>
			{user ? <UserMenu /> : null}
		</Stack>
	);
}
