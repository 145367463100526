import {faCaretDown, faGraduationCap} from '@fortawesome/free-solid-svg-icons';
import {Icon} from '@halp/ui';
import {AMBASSADORS, AMBASSADORS_BY_HIRING_STAGE} from '../data';
import {Metric} from '../Metric';
import style from './AmbassadorsSummary.module.css';

export function AmbassadorsSummary() {
	const bestAmbassador = AMBASSADORS.reduce((acc, ambassador) => {
		if (ambassador.metrics.handOff > acc.metrics.handOff) {
			return ambassador;
		}
		return acc;
	});

	return (
		<div className={style.AmbassadorsSummary}>
			<Metric
				title="Active Ambassadors"
				value={AMBASSADORS_BY_HIRING_STAGE.ACTIVE.length}
				tag={
					<>
						<Icon icon={faCaretDown} /> 1
					</>
				}
				tagColor="red"
			/>
			<Metric
				title="Most Referrals"
				value={`${bestAmbassador.first} ${bestAmbassador.last}`}
				tag={
					<>
						<Icon icon={faGraduationCap} /> {bestAmbassador.metrics.handOff}
					</>
				}
				tagColor="green"
			/>
		</div>
	);
}
